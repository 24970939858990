<section>
  <h3>Habilitação académica</h3>
  <p class="text-justify mt-2">Doutoramento em Direito (ramo de ciências jurídico-empresariais) na Faculdade de Direito
    da
    Universidade de
    Coimbra. No âmbito do Doutoramento realizou investigação no UNIDROIT-Instituto Internacional para a Unificação do
    Direito Privado (em Roma), no Instituto Suíço de Direito Comparado (em Lausanne) e na UNCITRAL (Comissão das Nações
    Unidas para o Direito Comercial Internacional).</p>
</section>
<section class="pt-4">
  <h3>Formação profissional</h3>
  <p class="text-justify mt-2">Pós-graduação em Direito Bancário, da Bolsa e dos Seguros (Faculdade de Direito
    da Universidade de
    Coimbra).
  </p>
  <p class="text-justify mt-2">Curso de Comércio Internacional pela Câmara de Comércio Internacional (ICC
    Portugal).</p>
  <p class="text-justify mt-2">NOVA Summer School on International Arbitration (Faculdade de Direito da
    Universidade Nova de Lisboa).
  </p>
  <p class="text-justify mt-2">Curso de Transporte Rodoviário Internacional (Faculdade de Direito da
    Universidade de Coimbra).</p>
  <p class="text-justify mt-2">Curso de Direito do Consumo (DECO- Associação Portuguesa para a Defesa do
    Consumidor).</p>
</section>
<section class="pt-4">
  <h3>Experiência profissional</h3>
  <p class="font-weight-bold">Actual:</p>
  <ol>
    <li>Advogado com cerca de 20 anos de exercício.</li>
    <li>Professor de Direito Civil e de Direito Comercial no Ensino Superior.</li>
    <li>Juiz-Árbitro em Tribunais Arbitrais.</li>
    <li>Investigador do Centro de Investigação em Justiça e Governação (JusGov) da Universidade do Minho.</li>
    <li>Formador certificado (IEFP) na área do Direito.</li>
  </ol>
  <p class="font-weight-bold">Anterior:</p>
  <ol>
    <li>
      Na Universidade Lusófona do Porto:
      <ol type="a" style="padding-left: 10px;">
        <li>Assistente universitário;
        </li>
        <li>Assessor Pedagógico da Direção da Faculdade de Direito e Ciência Política, de dezembro de 2014 a outubro de
          2018;</li>
        <li>Membro do Conselho Científico da Faculdade de Direito e Ciência Política, de dezembro de 2014 a julho de
          2019 (com funções de secretário);</li>
        <li>Membro do Conselho Pedagógico da Faculdade de Direito e Ciência Política, de dezembro de 2014 a julho de
          2019 (com funções de secretário);</li>
        <li>Diretor do Curso de Preparação para o Exame de Agregação da Ordem dos Advogados (3 edições) e Docente
          responsável pelo módulo de Deontologia Profissional (na 2ª e 3ª edições).</li>
      </ol>
    </li>
    <br>
    <li>Vereador da Câmara Municipal de Barcelos, no mandato 2009/2013.</li>
    <li>Vice-Presidente do CIAB-Tribunal Arbitral de Consumo no quadriénio 2009/2013.</li>
    <li>Docente do IPCA-Instituto Politécnico do Cávado e Ave em 2008/2009.</li>
  </ol>
</section>