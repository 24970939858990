<section>
  <h3>Artigos Científicos</h3>
  <p class="text-justify mt-2">Pires, César. "The Impact of the Ongoing European Legislative Process regarding Corporate
    Sustainability Due
    Diligence on Contracts between Companies and their Partners". GPR - Zeitschrift für das Privatrecht der Europäischen
    / Union European Union Private Law Review / Revue de droit privé de l’Union européenne 21 1 (2024): Aceite para
    publicação.</p>
  <p class="text-justify mt-2">
    Pires, César M.F. / Oliveira, António Cândido de, 2017 (Orgs.) “O ESTADO DA JUSTIÇA”, Edições Universitárias
    Lusófonas. Publicado.
  </p>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. "Os Direitos de retenção, de suspensão e de stoppage in
    transitu no
    contrato de
    venda internacional de mercadorias com transporte por via marítima". Themis - Revista da Faculdade de Direito da
    Universidade NOVA de Lisboa (2023): No prelo</p>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. "O regime supletivo de funcionamento da gerência plural nas
    sociedades
    por
    quotas". DSR - Direito das Sociedades em Revista 25 (2021): 179-204. Publicado.</p>
  <p class="text-justify mt-2">
    Pires, César M.F., 2017,
    <a rel="noreferrer"
      href="https://www.linkedin.com/redir/redirect?url=http%3A%2F%2Fwww%2Erevista-aji%2Ecom%2Farticulos%2F2017%2F174-195%2Epdf&amp;urlhash=zEUU&trk=public_profile_publication-title"
      title="A Posição jurídica do devedor face ao contrato de factoring" target="_blank">“A Posição jurídica do devedor
      face ao contrato de factoring”</a>,
    Actualidad Jurídica Iberoamericana. Publicado.
  </p>
  <p class="text-justify mt-2">
    Pires, César M.F., 2016,
    <a href="http://idibe.org/wp-content/uploads/2013/09/N%C2%BA-4-.pdf" title="Actualidad Jurídica Ibéroamericana"
      rel="noreferrer" target="_blank">“A transferência (Inter)nacional da sede da Sociedade Anónima Europeia”</a>,
    Actualidad Jurídica Ibéroamericana, N.º 4, febrero de 2016. Publicado.
  </p>
  <p class="text-justify mt-2">
    Pires, César M. F. 2013. <a target="_blank" href="http://revistas.ulusofona.pt/index.php/rfdulp/article/view/3251"
      rel="noreferrer">"A lei aplicável à sociedade anónima
      europeia (societas europaea)" </a>,
    Revista da Faculdade de Direito da Universidade Lusófona do Porto 2, 2: 117 – 134. Publicado.
  </p>
  <p class="text-justify mt-2">
    Pires, César M. F. 2007. "A Oferta Pública de Aquisição Tendente ao Domínio Total", Reflexões-Revista Cientifica
    da Universidade
    Lusófona do Porto, 2: 95 - 116 (<a href="/docs/article1.pdf" target="_blank" rel="noreferrer">PDF</a>).Publicado.
  </p>
</section>
<section class="pt-3">
  <h4>Capítulo de livro</h4>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. "O papel da vontade na CISG". In COMEMORANDO OS 100 ANOS
    DAS REGRAS DE
    HAIA
    (COMMEMORATING 100 YEARS OF THE HAGUE RULES), 99-128. Coimbra, Portugal: Instituto Jurídico da Faculdade de Direito
    da Universidade de Coimbra, 2022.
    <a target="_blank"
      href="https://estudogeral.uc.pt/bitstream/10316/103948/1/Comemorando%20os%20100%20anos%20das%20regras%20de%20Haia%20-%20CAP%2006.pdf">Documento
      PDF</a>
    Publicado
  </p>
</section>
<section class="pt-3">
  <h4>Edição de livro</h4>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira; Oliveira, António Cândido de. O estado da Justiça. Lisboa,
    Portugal:
    Edições
    Universitárias Lusófonas. 2017. Publicado</p>
</section>
<section class="pt-3">
  <h4>Livro</h4>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. A transferência do risco na venda internacional de
    mercadorias. Coimbra,
    Portugal:
    Almedina Editora. 2023.
    <a target="_blank"
      href="https://www.almedina.net/a-transferencia-do-risco-na-venda-internacional-de-mercadorias-1681991678.html">https://www.almedina.net/a-transferencia-do-risco-na-venda-internacional-de-mercadorias-1681991678.html</a>.
    Publicado
  </p>
</section>
<section class="pt-3">
  <h4>Recursos online</h4>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. O Regulamento Roma I (relativo à lei aplicável às
    obrigações
    contratuais) continua a
    aplicar-se no Reino Unido. 2022.
    <a target="_blank"
      href="https://www.linkedin.com/pulse/o-regulamento-roma-i-relativo-%25C3%25A0-lei-aplic%25C3%25A1vel-%25C3%25A0s-obriga%25C3%25A7%25C3%25B5es-pires/?trackingId=fSFKlc92TBubJreZSPr%2B7A%3D%3D">https://www.linkedin.com/pulse/o-regulamento-roma-i-relativo-%25C3%25A0-lei-aplic%25C3%25A1vel-%25C3%25A0s-obriga%25C3%25A7%25C3%25B5es-pires/?trackingId=fSFKlc92TBubJreZSPr%2B7A%3D%3D</a>.
  </p>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. Some questions about the first decision of the Belgian
    court in the
    dispute between
    AstraZeneca and the European Commission.. 2021.
    <a target="_blank"
      href="https://cisg-portugal.org/2021/06/20/some-questions-about-the-first-decision-of-the-belgian-court-in-the-dispute-between-astrazeneca-and-the-european-commission/">https://cisg-portugal.org/2021/06/20/some-questions-about-the-first-decision-of-the-belgian-court-in-the-dispute-between-astrazeneca-and-the-european-commission/</a>.
  </p>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. Force majeure under the “European Commission-AstraZeneca” &
    “European
    Commission-Curevac” contracts. 2021.
    <a target="_blank"
      href="https://cisg-online.org/Home/international-sales-law-news/force-majeure-under-the-european-commission-astrazeneca-and-european-commission-curevac-contracts">https://cisg-online.org/Home/international-sales-law-news/force-majeure-under-the-european-commission-astrazeneca-and-european-commission-curevac-contracts</a>.
  </p>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. O valor da causa na exoneração do passivo restante depois
    do Acórdão do
    Tribunal
    Constitucional n.º 70/2021. 2021.
    <a target="_blank"
      href="https://www.linkedin.com/pulse/o-valor-da-causa-na-exonera%25C3%25A7%25C3%25A3o-do-passivo-restante-depois-c%25C3%25A9sar-pires/?trackingId=QZBRCYhGozsCR8Fgi9jv8A%3D%3D">https://www.linkedin.com/pulse/o-valor-da-causa-na-exonera%25C3%25A7%25C3%25A3o-do-passivo-restante-depois-c%25C3%25A9sar-pires/?trackingId=QZBRCYhGozsCR8Fgi9jv8A%3D%3D</a>.
  </p>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. Abstract do Acórdão do Tribunal da Relação do Porto de 29
    de maio de
    2014 (Proc.
    254/05.6TBVLP.P1) elaborado para os projetos ‘CISG-online.org’ e ‘CISG Albert H. Kritzer Database. 2021.
    <a target="_blank"
      href="https://cisg-online.org/files/cases/13292/abstractsFile/5378_61366937.pdf">https://cisg-online.org/files/cases/13292/abstractsFile/5378_61366937.pdf</a>.
  </p>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. Os INCOTERMS 2020 no complexo modelo regulador da compra e
    venda
    internacional de
    mercadorias. 2021.
    <a target="_blank"
      href="https://www.linkedin.com/pulse/os-incoterms-2020-complexo-modelo-regulador-da-compra-c%C3%A9sar-pires/?articleId=6633695518029684736#comments-6633695518029684736&trk=public_profile_article_view">https://www.linkedin.com/pulse/os-incoterms-2020-complexo-modelo-regulador-da-compra-c%C3%A9sar-pires/?articleId=6633695518029684736#comments-6633695518029684736&trk=public_profile_article_view</a>.
  </p>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. Contratos: A modificação por alteração das circunstâncias.
    2020.
    <a target="_blank"
      href="https://www.linkedin.com/pulse/contratos-modifica%25C3%25A7%25C3%25A3o-por-altera%25C3%25A7%25C3%25A3o-das-c%25C3%25A9sar-pires/?trackingId=jIVR7WOEk3BmWCZBuuJWTQ%3D%3D">https://www.linkedin.com/pulse/contratos-modifica%25C3%25A7%25C3%25A3o-por-altera%25C3%25A7%25C3%25A3o-das-c%25C3%25A9sar-pires/?trackingId=jIVR7WOEk3BmWCZBuuJWTQ%3D%3D</a>.
  </p>
  <p class="text-justify mt-2">Pires, César Manuel Ferreira. Silk Road Economic Belt / One Belt One Road. 2020.
    <a target="_blank"
      href="https://www.linkedin.com/pulse/silk-road-economic-belt-one-c%C3%A9sar-pires/?articleId=6635492073787273216#comments-6635492073787273216&trk=public_profile_article_view">https://www.linkedin.com/pulse/silk-road-economic-belt-one-c%C3%A9sar-pires/?articleId=6635492073787273216#comments-6635492073787273216&trk=public_profile_article_view</a>.
  </p>

</section>