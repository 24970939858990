<div class="row">
  <div class="col-12 col-sm-4">
    <div class="welcome-image">
      <img src="images/CesarPhD.png" alt="César Manuel Ferreira Pires" class="img-fluid web-image">
    </div>
  </div>
  <div class="col-12 col-sm-8">
    <div class="pt-4">
      <h3>Bem-vindo</h3>
      <div class="welcome-message">
        <p class="text-justify">
          César Pires tem cerca de 20 anos de exercício da advocacia. Ao longo do tempo tem vindo a dedicar-se,
          preferencialmente, às áreas de Direito Civil, Direito Comercial/Empresarial, Direito Europeu e Direito
          Internacional Privado. Doutor em Direito (ciências jurídico-empresariais) pela Faculdade de Direito da
          Universidade de Coimbra.
        </p>
        <p class="text-justify">
          Juiz-árbitro em Tribunais Arbitrais e investigador no Centro de Investigação em Justiça e Governação (JusGov)
          da Universidade do Minho.
        </p>
      </div>
    </div>
  </div>
</div>