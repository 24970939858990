<footer class="footer">
  <div class="container">
    <div class="row pt-3 pb-3 pt-sm-0 pb-sm-0">
      <div class="col-12 col-sm-4 col-lg-6 p-sm-0">
        César Pires - Advogado
      </div>
      <div class="col-12 col-sm-8 col-lg-6 text-sm-right p-sm-0">
        <span class="d-block d-sm-inline">Tel.: <a href="tel:+351919003008">(+351) 91 90 03 008</a></span>
        <span class="d-none d-sm-inline"> / </span>
        <span class="d-block d-sm-inline">E-mail: <a href="mailto:cesarpires-10865p@adv.oa.pt">cesarpires-10865p@adv.oa.pt</a></span>
      </div>
    </div>
  </div>
</footer>
