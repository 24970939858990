<section>
  <h3>Áreas de exercício preferencial</h3>
  <ul>
    <li>Direito Comercial / Empresarial</li>
    <li>Direito Comercial Internacional</li>
    <li>Direito das Sociedades</li>
    <li>Direito dos Contratos</li>
    <li>Direito dos Transportes</li>
    <li>Direito dos Seguros</li>
    <li>Direito Bancário</li>
    <li>Direito do Consumo</li>
    <li>Direito da União Europeia</li>
    <li>Direito da Família</li>
    <li>Direito Reais</li>
    <li>Direito do Turismo</li>
    <li>Direito do Trabalho</li>
    <li>Direito da Propriedade Intelectual</li>
  </ul>
</section>
<section class="pt-3">
  <h3>Outras áreas de actuação</h3>
  <ul>
    <li>Direito Administrativo</li>
    <li>Direito do Urbanismo</li>
    <li>Direito do Ambiente</li>
    <li>Direito Fiscal</li>
    <li>Direito Criminal</li>
    <li>Direito Registral e Notarial</li>
    <li>Direito dos Valores Mobiliários</li>
  </ul>
</section>
